<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Code" vid="code" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="code"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.s_code') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="code"
                                                            v-model="formData.code"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6"></div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Supplier Name (En)" vid="supplier_name" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="supplier_name"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.s_name') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="supplier_name"
                                                            v-model="formData.supplier_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Supplier Name (Bn)" vid="supplier_name_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="supplier_name_bn"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.s_name_bn') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="supplier_name_bn"
                                                            v-model="formData.supplier_name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Contact No" vid="mobile" rules="required|min_value:11">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="mobile"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.contact_no') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            id="mobile"
                                                            v-model="formData.mobile"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="NID" vid="nid" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="nid"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.nid') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            id="nid"
                                                            v-model="formData.nid"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="email" vid="email">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="email"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.email') }}
                                                            </template>
                                                            <b-form-input
                                                            id="email"
                                                            v-model="formData.email"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Company Name (En)" vid="company_name" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="company_name"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.c_name_en') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="company_name"
                                                            v-model="formData.company_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Company Name (Bn)" vid="company_name_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="company_name_bn"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.c_name_bn') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="company_name_bn"
                                                            v-model="formData.company_name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Tin" vid="tin" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="tin"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.tin') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="tin"
                                                            v-model="formData.tin"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Vat Registration No" vid="vat_registration_no" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="vat_registration_no"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.vat_regis') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="vat_registration_no"
                                                            v-model="formData.vat_registration_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Trade Registration No" vid="trade_registration_no" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="trade_registration_no"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.trade_regis') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="trade_registration_no"
                                                            v-model="formData.trade_registration_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Address (En)" vid="address" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="address"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.address_en') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="address"
                                                            v-model="formData.address"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6">
                                                    <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="address_bn"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.address_bn') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="address_bn"
                                                            v-model="formData.address_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { supplierInformationStore, supplierInformationUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getformDataData()
            this.formData = tmp
        }
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                code: '',
                supplier_name: '',
                supplier_name_bn: '',
                mobile: '',
                nid: '',
                email: '',
                company_name: '',
                company_name_bn: '',
                tin: '',
                vat_registration_no: '',
                trade_registration_no: '',
                address: '',
                address_bn: ''
            }
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
    },
    methods: {
        getformDataData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${supplierInformationUpdate}/${this.id}`, this.formData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, supplierInformationStore, this.formData)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
