<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <iq-card>
            <template v-slot:body>
                <b-table-simple hover striped bordered small >
                  <b-tr>
                    <b-th>{{ $t('dae_config.s_code') }}</b-th>
                    <b-td>{{ $n(detailsData.code) }}</b-td>
                    <b-th>{{ $t('dae_config.s_name_en') }}</b-th>
                    <b-td>{{ detailsData.supplier_name }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>{{ $t('dae_config.s_name_bn') }}</b-th>
                    <b-td>{{ detailsData.supplier_name_bn }}</b-td>
                    <b-th>{{ $t('dae_config.contact_no') }}</b-th>
                    <b-td>{{ currentLocale === 'bn' ? 'o' + $n(detailsData.mobile, { useGrouping: false }) : '0' + $n(detailsData.mobile, { useGrouping: false }) }}</b-td>
                  </b-tr>
                  <tr>
                    <b-th>{{ $t('dae_config.nid') }}</b-th>
                    <b-td>{{ $n(detailsData.nid, { useGrouping: false }) }}</b-td>
                    <b-th>{{ $t('dae_config.email') }}</b-th>
                    <b-td>{{ detailsData.email }}</b-td>
                  </tr>
                  <b-tr>
                    <b-th>{{ $t('dae_config.c_name_en') }}</b-th>
                    <b-td>{{ detailsData.company_name }}</b-td>
                    <b-th>{{ $t('dae_config.c_name_bn') }}</b-th>
                    <b-td>{{ detailsData.company_name_bn }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>{{ $t('dae_config.tin') }}</b-th>
                    <b-td>{{ $n(detailsData.tin, { useGrouping: false }) }}</b-td>
                    <b-th>{{ $t('dae_config.vat_regis') }}</b-th>
                    <b-td>{{ $n(detailsData.vat_registration_no, { useGrouping: false }) }}</b-td>
                  </b-tr>
                  <tr>
                    <b-th>{{ $t('dae_config.trade_regis') }}</b-th>
                    <b-td>{{ $n(detailsData.trade_registration_no, { useGrouping: false }) }}</b-td>
                    <b-th>{{ $t('dae_config.address_en') }}</b-th>
                    <b-td>{{ detailsData.address }}</b-td>
                  </tr>
                  <tr>
                    <b-th>{{ $t('dae_config.address_bn') }}</b-th>
                    <b-td>{{ detailsData.address_bn }}</b-td>
                    <b-th></b-th>
                    <b-td></b-td>
                  </tr>
                </b-table-simple>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  // name: 'FormLayout',
  props: ['item'],
  created () {
    this.detailsData = this.item
  },
  data () {
    return {
      detailsData: {}
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  }
}
</script>
