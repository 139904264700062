
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_config.supplier_info') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          :label="$t('dae_config.s_name')"
                          label-for="material_type_id"
                          >
                        <v-select name="crop_id"
                        v-model="search.supplier"
                        label="text"
                        option-value="value"
                        option-text="text"
                        :options= supplierList
                        :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_config.supplier_info_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped  :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(code)="data">
                                          {{ $n(data.item.code, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                          {{ ($i18n.locale == 'bn' ? '০': '0') + $n(data.item.mobile,{ useGrouping:false }) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                          <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                              <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_view" title="Status" v-if="data.item.status === 1" @click="remove(data.item)">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)">
                                              <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="lg" :title="$t('dae_config.supplier_info_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="dtlsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { supplierInformationList, supplierInformationStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        supplier: 0
      },
      rows: [],
      item: '',
      materialLists: [],
      dtlsItemId: ''
    }
  },
  mounted () {
  },
  computed: {
    supplierList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.supplierList.filter(item => item.status === 1)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('dae_config.supplier_info') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_config.supplier_info') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('dae_config.s_code'), class: 'text-center' },
          { label: this.$t('dae_config.s_name'), class: 'text-center' },
          { label: this.$t('dae_config.c_name'), class: 'text-center' },
          { label: this.$t('dae_config.contact_no'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'code' },
          { key: 'supplier_name_bn' },
          { key: 'company_name_bn' },
          { key: 'mobile' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'code' },
          { key: 'supplier_name' },
          { key: 'company_name' },
          { key: 'mobile' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
       this.dtlsItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, supplierInformationStatus, item, 'incentive_grant', 'agMaterialList')
    },
    async loadData () {
        var supplierSerach = ''
        if (this.search.supplier) {
            supplierSerach = this.search.supplier.value
        }
        const params = Object.assign({}, this.search, { supplier_id: supplierSerach, page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        await RestApi.getData(incentiveGrantServiceBaseUrl, supplierInformationList, params).then(response => {
            if (response.success) {
              this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
              this.paginationData(response.data)
            } else {
              this.$store.dispatch('setList', [])
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getRelatinalData (data) {
      const agMaterialTypeList = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList

      const listData = data.map(item => {
        const agMaterialTypeObject = agMaterialTypeList.find(agmt => agmt.value === item.material_type_id)

        const agMaterialTypeData = {
          type_name: agMaterialTypeObject !== undefined ? agMaterialTypeObject.text_en : '',
          type_name_bn: agMaterialTypeObject !== undefined ? agMaterialTypeObject.text_bn : ''
          }

        return Object.assign({}, item, agMaterialTypeData)
      })
      return listData
    },
    getMaterialsInfo (typeId) {
      const dataObject = this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1 && item.material_type_id === typeId)
      return dataObject.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    }
  }
}
</script>
